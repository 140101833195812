import Vue from 'vue';
import App from './App.vue';
import SweetModal from 'sweet-modal-vue/src/plugin.js';

Vue.config.productionTip = false

Vue.use(SweetModal);



new Vue({
  render: function (h) { return h(App) },
}).$mount('#app')

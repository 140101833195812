<template>
  <div class="vo2">
      {{ calculateVO2 }}
  </div>
</template>

<script>
export default {
  name: 'Vo2',
  props: {
    distance: Number,
    hours: Number,
    minutes: Number,
    seconds: Number
  },

  computed: {
    calculateVO2(){
        let th = this.hours * 60;
        let tm = this.minutes * 1;
        let ts = this.seconds / 60;
        let ttime = th + tm + ts;
        let dist = this.distance;
        let d = dist / ttime;

        let p1 = Math.exp(-0.012778 * ttime);
        let p2 = Math.exp(-0.1932605 * ttime);
        p1 = 0.1894393 * p1;
        p2 = 0.2989558 * p2;

        let p = 0.8 + p1 + p2;

        let v = -4.60;
        v = v + (0.182258 * d);
        v = v + (0.000104 * d * d);
        let vo2 = Math.round(v/p);

        this.$emit('onVoChange', vo2);
        return vo2;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>

<template>
  <div id="app" class="mb-20">

    <div class=" mx-auto">
      <div class="bg-white border-gray-200 border-4 sm:rounded-lg mb-8 sm:w-auto inline-block ">
        <div class="pb-5">
          <h3 class=" text-lg leading-6 font-medium text-red-500 bg-white py-4">
            Race Time &amp; Distance
          </h3>
          <div class="px-2 md:px-4 pt-4 text-center text-sm text-gray-500 border-gray-200 border-t">
            <div class="flex flex-wrap mx-auto justify-center mb-2">
                <button :class="{ 'active': distanceInM == 1609.3 }" class="px-2 md:px-3 py-2 rounded-md mx-1 md:mx-2 mb-2 text-xs" @click="setMile()">Mile</button>
                <button :class="{ 'active': distanceInM == 5000 }" class="px-2 md:px-3 py-2 rounded-md mx-1 md:mx-2 mb-2 text-xs" @click="set5k()">5K</button>
                <button :class="{ 'active': distanceInM == 10000 }" class="px-2 md:px-3 py-2 rounded-md mx-1 md:mx-2 mb-2 text-xs" @click="set10k()">10K</button>
                <button :class="{ 'active': distanceInM == 21097.5 }" class="px-2 md:px-3 py-2 rounded-md mx-1 md:mx-2 mb-2 text-xs" @click="setHalf()">Half Marathon</button>
                <button :class="{ 'active': distanceInM == 42195 }" class="px-2 md:px-3 py-2 rounded-md mx-1 md:mx-2 mb-2 text-xs" @click="setFull()">Marathon</button>
            </div>
            <div class="flex flex-wrap mx-auto justify-center mb-4">
              <button :class="{ 'active': distanceInM == 50000 }" class="px-2 md:px-3 py-2 rounded-md mx-1 md:mx-2 mb-2 text-xs" @click="set50k()">50K</button>
              <button :class="{ 'active': distanceInM == 80465 }" class="px-2 md:px-3 py-2 rounded-md mx-1 md:mx-2 mb-2 text-xs" @click="set50m()">50M</button>
              <button :class="{ 'active': distanceInM == 100000 }" class="px-2 md:px-3 py-2 rounded-md mx-1 md:mx-2 mb-2 text-xs" @click="set100k()">100K</button>
              <button :class="{ 'active': distanceInM == 160930 }" class="px-2 md:px-3 py-2 rounded-md mx-1 md:mx-2 mb-2 text-xs" @click="set100m()">100M</button>
            </div>
            <div class="flex mx-auto justify-center mb-4 ">
              <div class="mx-2 text-left">
                <label class="block" for="hours">Hours</label>
                <input v-model.number="hours" type="number" class="rounded border p-2 w-16" placeholder="Hours" min="0" max="99">
              </div>
              <div class="mx-2 text-left">
                <label class="block" for="minutes">Minutes</label>
                <input v-model.number="minutes" type="number" class="rounded border p-2 w-16" placeholder="Minutes">
              </div>
              <div class="mx-2 text-left">
                <label class="block" for="seconds">Seconds</label>
                <input v-model.number="seconds" type="number" class="rounded border p-2 w-16" placeholder="Seconds">
              </div>
            </div>
            <div class="flex mx-auto justify-center ">
              <div class="mx-2 text-left">
                <label class="block" for="distance">Distance</label>
                <input v-model="distance" type="number" class="rounded border p-2" placeholder="Distance">
              </div>
              <div class="text-left pl-5">
                <label class="block" for="xxx">Units</label>
                <div class="flex mt-3">
                  <div class="mr-5">
                    <input type="radio" id="mi" value="mi" v-model="units">
                    <label for="mi"> Mi</label>
                  </div>
                  <div>
                    <input type="radio" id="km" value="km" v-model="units">
                    <label for="km"> Km</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sm:flex flex-wrap justify-between items-start">
        <div class="px-4 sm:w-full lg:w-1/3 mb-4">
          <div class="bg-white  border-gray-200 border-4 sm:rounded-lg ">
            <div class="border-gray-200">
              <div class="px-4 py-5 sm:px-6 ">
                <h3 class="text-lg text-center leading-6 font-medium text-red-500">
                  Race Splits
                </h3>
              </div>
              <dl>
                <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">
                    Mile Pace
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ formattedPace }}
                  </dd>
                </div>
                <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">
                    100m Splits
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ rounded(calculate100) }}
                  </dd>
                </div>
                <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">
                    200m Splits
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ rounded(calculate200) }}
                  </dd>
                </div>
                <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">
                    400m Splits
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ rounded(calculate400) }}
                  </dd>
                </div>
                <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">
                    800m Splits
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ calculate800 }}
                  </dd>
                </div>
                <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">
                    1000m Splits
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ calculate1000 }}
                  </dd>
                </div>

              </dl>
            </div>
          </div>
        </div>
        <div class="px-4 sm:w-1/2 lg:w-1/3 mb-4">
          <div class="bg-white border-gray-200 border-4  sm:rounded-lg ">


            <div class="">
              <div class="px-4 py-5 sm:px-6">
                <h3 class="text-lg text-center leading-6 font-medium text-red-500">
                  Training Paces
                </h3>
              </div>
              <dl>
                <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">
                    VDOT
                    <span class="cursor-pointer text-gray-400 hover:text-red-500" @click="openModal('vdot')">
                      <i class="fas fa-question-circle"></i>
                    </span>
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <vo2 @onVoChange="voChangeHandler" :distance="distanceInM" :hours="hours" :minutes="minutes" :seconds="seconds"></vo2>
                  </dd>
                </div>
                <div v-if="vdotPace" class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">
                    Easy Pace
                    <span class="cursor-pointer text-gray-400 hover:text-red-500" @click="openModal('easy')">
                      <i class="fas fa-question-circle"></i>
                    </span>
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ formatTime(vdotPace.e.mile) }}
                  </dd>
                </div>
                <div v-if="vdotPace" class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">
                    Marathon Pace
                    <span class="cursor-pointer text-gray-400 hover:text-red-500" @click="openModal('marathon')">
                      <i class="fas fa-question-circle"></i>
                    </span>
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ formatTime(vdotPace.m.mile) }}
                  </dd>
                </div>
                <div v-if="vdotPace" class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">
                    Threshold Pace
                    <span class="cursor-pointer text-gray-400 hover:text-red-500" @click="openModal('threshold')">
                      <i class="fas fa-question-circle"></i>
                    </span>
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <div class="px-4" v-if="vdotPace.t['400m']">400m: {{ formatTime(vdotPace.t['400m']) }}</div>
                    <div class="px-4" v-if="vdotPace.t['1000m']">1000m: {{ formatTime(vdotPace.t['1000m']) }}</div>
                    <div class="px-4" v-if="vdotPace.t['mile']">Mile: {{ formatTime(vdotPace.t['mile']) }}</div>
                  </dd>
                </div>

                <div v-if="vdotPace" class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">
                    Interval Pace (Daniel's)
                    <span class="cursor-pointer text-gray-400 hover:text-red-500" @click="openModal('interval')">
                      <i class="fas fa-question-circle"></i>
                    </span>
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <div class="px-4" v-if="vdotPace.i['400m']">400m: {{ formatTime(vdotPace.i['400m']) }}</div>
                      <div class="px-4" v-if="vdotPace.i['1000m']">1000m: {{ formatTime(vdotPace.i['1000m']) }}</div>
                      <div class="px-4" v-if="vdotPace.i['1200m']">1200m: {{ formatTime(vdotPace.i['1200m']) }}</div>
                      <div class="px-4" v-if="vdotPace.i['mile']">Mile: {{ formatTime(vdotPace.i['mile']) }}</div>
                  </dd>
                </div>
                <div v-if="vdotPace" class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">
                    Repition Pace (Daniel's)
                    <span class="cursor-pointer text-gray-400 hover:text-red-500" @click="openModal('repition')">
                      <i class="fas fa-question-circle"></i>
                    </span>
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <div class="px-4" v-if="vdotPace.r['200m']">200m: {{ formatTime(vdotPace.r['200m']) }}</div>
                      <div class="px-4" v-if="vdotPace.r['400m']">400m: {{ formatTime(vdotPace.r['400m']) }}</div>
                      <div class="px-4" v-if="vdotPace.r['800m']">800m: {{ formatTime(vdotPace.r['800m']) }}</div>
                  </dd>
                </div>

              </dl>
            </div>
          </div>
        </div>
        <div class="px-4 sm:w-1/2 lg:w-1/3 mb-4">
          <div class="bg-white border-gray-200 border-4  sm:rounded-lg ">
            <div class="">
              <div class="px-4 py-5 sm:px-6 ">
                <h3 class="text-lg text-center leading-6 font-medium text-red-500">
                  Race Projections
                </h3>
              </div>
              <dl>
                <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">
                    400m
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ estimatedDistance(400) }}
                  </dd>
                </div>
                <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">
                    800m
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ estimatedDistance(800) }}
                  </dd>
                </div>
                <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">
                    Mile
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ estimatedDistance(1609.3) }}
                  </dd>
                </div>
                <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">
                    5K
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ estimatedDistance(5000) }}
                  </dd>
                </div>
                <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">
                    10K
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ estimatedDistance(10000) }}
                  </dd>
                </div>
                <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">
                    Half Marathon
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ estimatedDistance(21097.5) }}
                  </dd>
                </div>
                <div class="bg-gray-50  px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">
                    Marathon
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ estimatedDistance(42195) }}
                  </dd>
                </div>

              </dl>
            </div>
          </div>
        </div>



      </div>
    </div>

      <sweet-modal ref="sweetmodal">
        <div v-if="modalText">
          <h3 class="text-red-500 mb-4">{{ this.modalText.title }}</h3>
          <div class="text-left px-8 text-sm" v-html="this.modalText.content">
          </div>
        </div>
      </sweet-modal>
    </div>
  </div>

</template>

<script>



const parse = require("./parse.js");
const paces = require("./paces.js");
const vdot = require("./vdot.js");



function toSeconds(hours, minutes, seconds) {
    return hours * 60 * 60 + minutes * 60 + seconds;
}


const distances = {
    "Marathon": { name: 'Marathon', distance: 42195, worldRecord: toSeconds(2, 1, 39), defaultTime: toSeconds(2, 49, 59) }, //  2:01:39
    "Half Marathon": { name: 'Half Marathon', distance: 21097.5, worldRecord: toSeconds(0, 58, 1), defaultTime: toSeconds(1, 21, 59) }, // 58:01
    "5K": { name: '5K', distance: 5000, worldRecord: toSeconds(0, 12, 51), defaultTime: toSeconds(0, 16, 59) }, //12:51
    "5M": { name: '5M', distance: 8047, worldRecord: toSeconds(0, 22, 12), defaultTime: toSeconds(0, 30, 0) }, // 35:00
    "10K": { name: '10K', distance: 10000, worldRecord: toSeconds(0, 26, 24), defaultTime: toSeconds(0, 35, 59) }, // 26:24
    "15K": { name: '15K', distance: 15000, worldRecord: toSeconds(0, 41, 5), defaultTime: toSeconds(1, 2, 59) }, // 41:05
    "10M": { name: '10M', distance: 16094, worldRecord: toSeconds(0, 44, 24), defaultTime: toSeconds(1, 10, 0) }, // 1:10:00
    "20K": { name: '20K', distance: 20000, worldRecord: toSeconds(0, 55, 21), defaultTime: toSeconds(1, 24, 59) }, // 55:21
    "30K": { name: '30K', distance: 30000, worldRecord: toSeconds(1, 26, 45), defaultTime: toSeconds(2, 7, 59) }, // 1:26:45
    "50K": { name: '50K', distance: 50000, worldRecord: toSeconds(2, 43, 38), defaultTime: toSeconds(3, 44, 59) }, // 2:43:38
};


import Vo2 from './components/Vo2';

export default {
  name: 'App',
  components: {
    Vo2
  },
  data() {
    return {
      p: paces,
      hours: 0,
      minutes: 17,
      seconds: 59,
      distance: '5',
      units: 'km',
      timesInSeconds: [
        24 * 60 * 60, // Day
        60 * 60, // Hour
        60, // Minute
        1, // Second
      ],
      vo2Number: null,
      vdotPace: null,
      modalText: null,
      info: {
        vdot: {
          title: 'VDOT',
          content: "Based on Jack Daniel's VDOT tables. This value is useful in determining training paces & race prediction times."
        },
        easy: {
          title: 'Easy Pace',
          content: "<p>Easy pace running refers to warm-ups, cool-downs , recovery runs, recovery running within a workout and generally long runs.</p><p>Generally in the range of 59-74% of VO2max or 65-79% of your HRmax. In general, Easy running is running at a comfortable, conversational pace, which certainly may vary daily, depending on how you are feeling. You may be up to 20 seconds slower or faster than the specified pace on a given day.</p><p>Running at your Easy pace promotes physiological benefits that build a solid base from which higher-intensity training can be performed. The heart muscle is strengthened, muscles receive increased blood supplies and increase their ability to process oxygen delivered through the cardiovascular system.</p>"
        },
        marathon: {
          title: 'Marathon Pace',
          content: '<p>Used to experience race pace conditions for those training for a marathon or simply as an alternative to Easy pace running for beginners on long run days.</p><p>Should be about 75-84% of VO2max or 80-90% of HRmax.</p>'
        },
        threshold: {
          title: 'Threshold Pace',
          content: '<p>Steady, prolonged or tempo runs or intermittent runs, also called cruise intervals. This pace will improve endurance. 83-88% of VO2max or 88-92% of HRmax</p>'
        },
        interval: {
          title: 'Interval Paces',
          content: '<p>95-100% of VO2max or 98-100% of HRmax. Intervals should be "hard" but not all-out running.Usually at a pace that you could maintain for about 10-15 minutes in a serious race. Intervals are best if they involve runs of 3 to 5 minutes each (800m and 1000m workbouts are typical), with jog recoveries of similar duration (not necessarily, equal distance); relative to the runs they follow. If a workout calls for "hard" runs, then go by feel and imagine 5k race pace, as the intensity of each run.</p>'
        },
        repition: {
          title: 'Repition Pace',
          content: '<p>Repition pace will improve speed and running economy. Rest for repitition pace should be relatively long. Recovery should be long enough so that each repitition feels no more difficult than the previous.</p>'
        }
      }

    }
  },

  watch: {
    seconds: function (val) {
      if ( val > 59 ) {
        this.seconds = 0;
        this.minutes++;
      }
      if ( val < 0 ) {
        this.seconds = 59;
        if( this.minutes > 0 ) {
          this.minutes--;
        }
      }
    },
    minutes: function (val) {
      if ( val > 59) {
        this.minutes = 0;
        this.hours++;
      }
      if ( val < 0) {
        this.minutes = 59;
        if( this.hours > 0) {
          this.hours--;
        }
      }
    },
  },

  methods: {

    openModal(i) {
      this.modalText = this.info[i];
      this.$refs.sweetmodal.open();
    },

    setMile() {
      this.setDistanceAndTime('mi',1, 0,4,59);
    },

    set5k() {
      this.setDistanceAndTime('km',5, 0, 16, 59);
    },
    set10k() {
      this.setDistanceAndTime('km',10, 0, 35, 59);
    },

    setHalf() {
      this.setDistanceAndTime('km',(21097.5/1000), 1, 21, 59);
    },

    setFull() {
      this.setDistanceAndTime('km',(42195/1000), 2, 49, 59);
    },

    set50k() {
      this.setDistanceAndTime('km',50, 4, 0, 0);
    },

    set50m() {
      this.setDistanceAndTime('mi',50, 8, 0, 0);
    },
    set100k() {
      this.setDistanceAndTime('km',100, 10, 0, 0);
    },
    set100m() {
      this.setDistanceAndTime('mi',100, 18, 0, 0);
    },

    setDistanceAndTime(u, d, h, m, s) {
      this.units = u;
      this.distance = d;
      this.hours = h;
      this.minutes = m;
      this.seconds = s;
    },

    voChangeHandler(x) {
      this.vo2Number = x;
      this.vdotPace = vdot.PACES[x];
    },

    zeroPad(number) {
      return `0${number}`.slice(-2);
    },

    formatTime(totalSeconds) {
      if (typeof totalSeconds !== "number") return "";
      const times = [];
      let seconds = Math.round(totalSeconds);
      this.timesInSeconds.forEach((timeInSeconds) => {
        if (times.length || seconds >= timeInSeconds) {
          const num = Math.floor(seconds / timeInSeconds);
          times.push(this.zeroPad(num));
        }
        seconds %= timeInSeconds;
      });
      if (times.length < 1) {
        times.push("00", "00");
      } else if (times.length < 2) {
        times.unshift("00");
      }
      return times.join(":").replace(/^0/, "");
    },

    rounded(x) {
      return Math.round(x*10) / 10;
    },

    estimatedDistance(x){
      return this.formatTime(this.totalSeconds * Math.pow(x/this.distanceInM, 1.06) );
    }

  },

  computed: {

    totalSeconds() {
      return this.hours * 3600 + this.minutes * 60 + this.seconds;
    },

    distanceInM() {
      if ( this.units == 'km') {
        return this.distance * 1000;
      }
      return this.distance * 1609.3;
    },

    race() {
      return this.distance + this.units;
    },

    formattedPace() {
      const imperial = true;
      // imperial = (imperial || length.endsWith("mi") && !metric);
      const lengthFactor = imperial ? 1609.3 : 1000;
      const lengthLabel = imperial ? "mi" : "km";
      const timeInSeconds = parse.time(`${this.hours}:${this.minutes}:${this.seconds}`);
      const meters = parse.length(this.race);
      const secondsPerLength = timeInSeconds / (meters / lengthFactor);
      return this.formatTime(secondsPerLength);
    },

    calculate400() {
      const imperial = true;
      // imperial = (imperial || length.endsWith("mi") && !metric);
      const lengthFactor = 400;
      const lengthLabel = imperial ? "mi" : "km";
      const timeInSeconds = parse.time(`${this.hours}:${this.minutes}:${this.seconds}`);
      const meters = parse.length(this.race);
      const secondsPerLength = timeInSeconds / (meters / lengthFactor);
      return secondsPerLength;
      return this.formatTime(secondsPerLength);
    },

    calculate100() {
      return this.calculate400/4;
    },

    calculate200() {
      return this.calculate400/2;
    },

    calculate800() {
      return this.formatTime(this.calculate400*2);
    },
    calculate1000() {
      return this.formatTime(this.calculate100*10);
    }

  }
}
</script>

<style lang="scss">

.sweet-action-close {
  width: auto !important;
  height: auto !important;
  padding-top: 2px;
  &:hover {
    background-color: #e74c3c !important;
  }
}
.border-gray-200 {
  // border-color: #1b7fff;
}

.text-red-500 {
  color: #e74c3c;
}

#app {
  font-family: 'Inter', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

button,
button:active,
button:focus {
  outline: none;
  border: none;
  background-color: #b2bec3;
  color: white;
}
button.active {
  border: none;
  background-color: #e74c3c;
  color: white;
}

body {
  background-image: url('./assets/track.svg');
  background-repeat: no-repeat;
  background-position: 5% -40%;
  // background-attachment: fixed;
  padding: 0;
  background-size: 600%;
  letter-spacing: 0.05rem;
  overflow: scroll;

  @media only screen and (min-width: 600px) {
    background-size: 125%;
    background-attachment: fixed;
    background-position: 15% 50%;
  }
}

.text-gray-500 {
  color: black;
}

.text-sm {
  font-size: 13px;
}

p {
  margin-bottom: 10px;
}

.sweet-modal .sweet-box-actions {
  z-index: 99999;
}
</style>
